import { breakpoints, theme } from "@shapeable/theme";
import { css } from "styled-components";

export const RHODIAN_YELLOW = '#D2A600';
export const RHODIAN_BLUE = '#85B0E7';
export const SLIDESHOW_FADE = '#939391';

export const themeOverrides = {
  font: {
    family: {
      serif: "'Cormorant', georgia, serif",
      sans: 'Montserrat, sans-serif',
      heading: 'Cormorant, sans-serif',
      copy: 'Montserrat, sans-serif',
    }
  },
  color: {
    primary: RHODIAN_BLUE,
    link: RHODIAN_BLUE,
    heading: 'dark',
    
    linkHover: RHODIAN_BLUE,

    invertedLink: '#FFF',
    invertedLinkActive: 'light',

    invertedMenuActive: RHODIAN_BLUE,
    invertedMenuHover: '#FFF',
    invertedMenuHoverBackground: RHODIAN_BLUE,

  },
};

export const FontSizeStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(17)};
  `
});


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    ${FontSizeStyles};
    
    .shp--slice-layout-content {
      h2 {
        text-transform: uppercase;
      }
    }

    .shp--slice-content-image {
      h2 {
        font-family: ${theme.FONT('heading')};
        font-size: 2.25em; 
        margin-bottom: ${theme.UNIT(4)};
        margin-top: ${theme.UNIT(4)};
        font-weight: 300;
      }
    }

    .shp--slice-section-color-block__body {

      .shp--content {
        font-weight: 400;
      }
  
      font-size: ${theme.FONT_SIZE(15)};

      h3 {
        margin-top: 0;
        margin-bottom: ${theme.UNIT(4)};
        font-size: 2.25em;
      }
    }

  `,
});


